<template>
  <div class="actIssues">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">账号问题</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <p>
        本平台登录会自动创建账号，需保存账号凭证或绑定手机号码，才能记录之前的账号信息。受行业限制，APP无法正常使用时需升级，未绑定手机号码会导致账号信息丢失。
      </p>

      <p>
        请及时绑定手机号码或保存账号凭证，以免VIP信息丢失，造成巨大财产损失！
      </p>

      <p>
        账号丢失的用户可在
        『我的』页面-账号找回，原账号的VIP信息会转移至新账号上。
      </p>

      <p>
        『账号凭证』『手机绑定』都没有的情况下，如账号VIP信息丢失，可以提供VIP支付充值凭证截图联系在线客服为您查询核实恢复VIP。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.actIssues {
  height: 100%;
  // background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #f3f3f3;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 16px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #333;
    line-height: 30px;
    p {
      margin-bottom: 30px;
    }
  }
}
</style>
